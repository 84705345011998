<template>
  <div>
    <div class="row bg-black">
      <div class="col-lg-6">
          <h1 class=" tw-text-red-300">ACPlayer: 还可以听音乐~</h1>
          <p class=" tw-text-red-500">
            这是一款专为音乐爱好者打造的本地音乐播放器, 拥有强大的功能和简洁易用的界面。
          </p>
          <p class="tw-text-gray-300">
            WIFI上传: 方便通过WIFI上传音乐到手机, 随时随地享受音乐。
          </p>
          <p class="tw-text-gray-300">
            自定义播放列表: 根据自己的喜好创建和编辑播放列表, 让音乐更符合你的心情。
          </p>
          <p class="tw-text-gray-300">
            编辑音乐信息: 可以编辑音乐的信息, 让音乐更加完美。
          </p>
          <p class="tw-text-gray-300">
            倍速播放: 支持0.5-2倍速播放, 满足你的不同需求。
          </p>
          <p class="tw-text-gray-300">
            定时关闭: 可以设置定时关闭, 让你在睡前也能享受音乐。
          </p>
          <br>
      
        <a href="https://apps.apple.com/cn/app/id6471982583" class="btn btn-outline-light btn-lg download-buttons">
          <i class="fab fa-apple"></i>
          Download
        </a>
      </div>
      <div class="col-lg-6">
        <img class="appstore-img" src="../assets/images/appstore.png" alt="">
      </div>
    </div>
    <div class="tw-flex tw-flex-row tw-align-middle tw-justify-center tw-mt-8">
      <div class="tw-flex-1 tw-text-right tw-text-gray-600">联系方式: 736726853@qq.com</div>
      <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
      <a style="text-decoration: none;" class="tw-flex-1 tw-text-left tw-text-gray-600" href="https://beian.miit.gov.cn/" target="_blank">备案编号: 京ICP备2024070228号</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayerView',
  components: {
  }
}
</script>

<style scoped>
.bg-black {
  background-color: #000;
  color: #fff;
  padding: 50px 0;
}
.appstore-img {
  width: 200px;
}
</style>